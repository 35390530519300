















































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'

import NotificationMixin from '@/mixins/notificationMixin'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class RecoverAcessIndex extends Mixins(NotificationMixin) {
  done = true
  form = {
    email: ''
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })

    extend('email', {
      ...email,
      message: 'The email must be a valid email address.'
    })
  }

  created () {
    this.validations()
  }

  submit () {
    this.done = false

    axios.post('user/password/recover', snakeCaseKeys(this.submitForm, { deep: true }))
      .then(() => {
        this.setNotification('success', 'Email de recuperação de senha enviado')
      })
      .catch(err => {
        const response = err.response.data
        this.setNotification('error', response.error)
      })
      .finally(() => { this.done = true })
  }

  private get submitForm () {
    return {
      email: this.form.email,
      eventId: this.$route.query.eventId,
      voucherId: this.$route.query.voucherId
    }
  }
}
